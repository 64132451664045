import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//分页查询车场出入口
export function searchParkingLotGateByParamPage(params) {
    return request.post('/parkingLotInfo/searchParkingLotGateByParamPage?' + objectToQueryString(params));
}

//分页查询车场出入口
export function saveOrUpdateParkingLotGate(params) {
    return request.post('/parkingLotInfo/saveOrUpdateParkingLotGate?' + objectToQueryString(params));
}

//删除出入口
export function deleteParkingLotGate(params) {
    return request.post('/parkingLotInfo/deleteParkingLotGate?' + objectToQueryString(params));
}

//分页查询车场出入口
export function operationParkingLotGate(params) {
    return request.post('/parkingLotInfo/operationParkingLotGate?' + objectToQueryString(params));
}

//根据车场查询出入口
export function findParkingGateByParam(params) {
    return request.post('/parkingLotInfo/findParkingGateByParam?' + objectToQueryString(params));
}

// 获取场内码链接或者车道码链接
export function getParkingQrCode(params) {
    return request.post('/parkingLotInfo/getParkingQrCode?' + objectToQueryString(params));
}

//查询抬杆记录
export function searchOperationGateRecords(params) {
    return request.post('/parkingLotInfo/searchOperationGateRecords?' + objectToQueryString(params));
}
