<template>
  <div v-loading="isLoading" class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div class="font-size16 margin-left30" style="color: black;font-weight: bold;display: flex; align-items: center;">
        通道配置 - {{ parkingName }}
      </div>
    </div>
    <Gate ref="space" :parking-id="parkingId" :parking-name="parkingName" @success="onSuccess"/>
  </div>
</template>

<script>
import {getParkingDetail} from "@/api/parking";
import Gate from "@/pages/parking/components/ParkingGate.vue";
import {MessageBox} from "element-ui";
import {checkToken} from "@/api/common";

export default {
  name: "add-parking",
  components: {
    Gate,
    // AddParkingBaisc,
    // ShareRules,
    // Operation,
    // Extension,
    // Price,
  },
  data() {
    return {
      parkingId: "",
      parkingName: "",
      currentTab: "basic",
      type: "add",
      parkingModelJSON: "",
      isLoading: false,
    };
  },
  methods: {
    //返回上一页
    goBack() {
      this.$confirm("确定退出配置通道吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var item =  {
          "fullPath": "/parking_manage/add?motionType=0",
          "hash": "",
          "meta": {
            "requiresAuth": true,
            "isAdmin": true,
            "icon": "el-icon-bell",
            "hidden": true,
            "title": "停车场信息维护"
          },
          "name": "add",
          "params": {},
          "path": "/parking_manage/add",
          "query": {
            "motionType": "0"
          }
        };
        this.$store.commit("app/removeRoute", item);
        this.$router.back();
      });
    },
    onBeforeLeave() {
      if (!this.parkingId) {
        this.$message({
          message: "请先完成车场基本信息",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    async getParkingDetail(parkingId) {
      this.isLoading = true;
      const res = await getParkingDetail(parkingId);
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.parkingName = returnObject.name;
        this.parkingModelJSON = JSON.stringify(returnObject);
      }
    },
    onSuccess(e) {
      this.parkingId = e.id;
      this.parkingName = e.name;
    },
    onExtensionSuccess() {
      this.getParkingDetail(this.parkingId);
    },
  },
  mounted() {
    checkToken()
        .then(res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            const parkingId = this.$route.query.parkingId;
            const parkingName = this.$route.query.parkingName;
            if (parkingId) {
              this.parkingId = parkingId;
              this.parkingName = parkingName;
              this.$nextTick(() => {
                this.$refs.space.parkingId = parkingId;
                this.$refs.space.parkingName = parkingName;
                // this.$refs.passage.getNumber();
                // this.$refs.passage.getGangtingDeviceList();
                this.$refs.space.search();
              });
              // this.getParkingDetail(this.parkingId);
            }
          }
        })
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
