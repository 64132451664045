<template>
  <div v-loading="isLoading" class="padding18 border-radius4" style="background: white;">
    <easy-card :show-right-btn="true"
               right-btn-text="新增"
               title="入口配置"
               @addItem="addGate(0)"
            >
      <el-table :data="inletList" :key="itemKey" class="common-table" style="width: 100%">
        <el-table-column
            label="编号"
            prop="code"
            width="100"
        ></el-table-column>
        <el-table-column label="通道名称" prop="name">
          <template slot-scope="scope">
            <el-input
                @change="changedItem(scope.row)"
                v-model="scope.row.name"
                placeholder="请输入通道名称"/>
          </template>
        </el-table-column>
        <el-table-column label="设备IP地址" prop="ip">
          <template slot-scope="scope">
            <el-input
                @change="changedItem(scope.row)"
                v-model="scope.row.ip"
                placeholder="请输入设备IP地址"
            />
          </template>
        </el-table-column>
        <el-table-column label="登录账号" prop="username">
          <template slot-scope="scope">
            <el-input
                @change="changedItem(scope.row)"
                v-model="scope.row.username"
                placeholder="请输入登录账号"
            />
          </template>
        </el-table-column>
        <el-table-column label="登录密码" prop="password">
          <template slot-scope="scope">
            <el-input
                @change="changedItem(scope.row)"
                v-model="scope.row.password"
                placeholder="请输入登录密码"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.haveChanged || !scope.row.id"
                class="common-text font-size14"
                size="small"
                type="text"
                @click="goSave(scope.row)"
            >保存</el-button>
            <el-button
                style="color: #EA5504!important;"
                size="small common-text"
                type="text"
                @click="goDelete(scope.row, 0)"
            >删除</el-button>
          </template>

        </el-table-column>
      </el-table>
    </easy-card>

    <easy-card :show-right-btn="true"
               right-btn-text="新增"
               title="出口配置"
               @addItem="addGate(1)"
    >
      <el-table :data="outletList" :key="itemKey" class="common-table" style="width: 100%">
        <el-table-column
            label="编号"
            prop="code"
            width="100"
        ></el-table-column>
        <el-table-column label="通道名称" prop="name">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name"
                      @change="changedItem(scope.row)"
                      placeholder="请输入通道名称"/>
          </template>
        </el-table-column>
        <el-table-column label="设备IP地址" prop="ip">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.ip"
                @change="changedItem(scope.row)"
                placeholder="请输入设备IP地址"
            />
          </template>
        </el-table-column>
        <el-table-column label="登录账号" prop="username">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.username"
                @change="changedItem(scope.row)"
                placeholder="请输入登录账号"
            />
          </template>
        </el-table-column>
        <el-table-column label="登录密码" prop="password">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.password"
                @change="changedItem(scope.row)"
                placeholder="请输入登录密码"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.haveChanged || !scope.row.id"
                class="common-text font-size14"
                size="small"
                type="text"
                @click="goSave(scope.row)"
            >保存</el-button>
            <el-button
                style="color: #EA5504!important;"
                size="small common-text"
                type="text"
                @click="goDelete(scope.row, 1)"
            >删除</el-button>
          </template>

        </el-table-column>
      </el-table>
    </easy-card>
  </div>
</template>

<script>
import {
  searchParkingLotGateByParamPage,
  saveOrUpdateParkingLotGate,
  deleteParkingLotGate,
} from "@/api/entranceMonitoring";
import BaseMixin from "@/mixins/base";

export default {
  name: "passage",
  mixins: [BaseMixin],
  // components: {Location},
  props: {
    parkingId: String,
    parkingName: String,
  },
  rules: {
    customizeCode: [
      {required: true, message: "自定义编号不能为空"},
      {min: 3, message: "自定义编号至少三个字符"}
    ],
  },
  data() {
    return {
      itemKey: 0,
      dialogVisible: false,
      map: null,
      mk: null,
      keyword: "",
      point: {},
      focusedParkingLocation: "",
      focusedItem:null,
      // parkingId: "",
      inletList: [],
      outletList: [],
      out: [],
      // entryNumber: 0,
      // outNumber: 0,
      isLoading: false,
      entrySnCodeNumber: 100,
      outSnCodeNumber: 100,
      pagination: {
        pageNo:1,
        pageSize: 99,
        totalSize: 0,
        totalPages: 1
      },
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initMap();
        });
      }
    },
  },
  methods: {
    changedItem(item){
      if(item.id){
        item.haveChanged = true
        this.$nextTick(()=>{
          this.itemKey++;
        })
      }
    },
    addGate(type){
      console.log('addInlet', type)
      if(type==0){
        this.inletList.push({type:0,parkingLotId:this.parkingId,})
      }else{
        this.outletList.push({type:1,parkingLotId:this.parkingId,})
      }
    },
    querySearchAsync(str, cb) {
      var options = {
        onSearchComplete: function (res) {
          var s = []; //  == BMAP_STATUS_SUCCESS
          if (local.getStatus() === 0) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i));
            }
            cb(s);
          } else {
            cb(s);
          }
        },
      };
      var local = new window.BMap.LocalSearch(this.map, options);
      local.search(str);
    },
    showModal(item) {
      console.log(item.systemCode)
      this.focusedItem = item
      this.focusedParkingLocation = item.parkingLocation
      this.dialogVisible = true;
    },
    search() {
      this.entry = [];
      this.out = [];

      if(this.parkingId===''){
        return false
      }

      this.isLoading = true;
      this.getInletList()
      this.getOutletList()
    },
    getInletList(){
      Promise.all([
        searchParkingLotGateByParamPage({parkingLotId: this.parkingId, type: 0, pageNo: this.pagination.pageNo, pageSize: this.pagination.pageSize}),
      ])
          .then((resList) => {
            this.isLoading = false;
            const res = resList[0];
            if (res.code === 30 && res.result) {
              var returnObject = res.returnObject
              this.inletList = returnObject.records
            }
          })
          .catch((e) => console.log(e));
    },
    getOutletList(){
      Promise.all([
        searchParkingLotGateByParamPage({parkingLotId: this.parkingId, type: 1, pageNo: this.pagination.pageNo, pageSize: 99}),
      ])
          .then((resList) => {
            this.isLoading = false;
            const res = resList[0];
            if (res.code === 30 && res.result) {
              var returnObject = res.returnObject
              this.outletList = returnObject.records
            }
          })
          .catch((e) => console.log(e));
    },
    goSave(row){
      console.log(row)
      // console.log(this.inletList.filter(s => s.systemCode === row.systemCode)[0])
      if(!row.name || row.name.trim().length<1){
        this.$message.error('通道名称不能为空')
        return false
      }
      if(!row.ip || row.ip.trim().length<1){
        this.$message.error('设备IP地址不能为空')
        return false
      }
      if(!row.username || row.username.trim().length<1){
        this.$message.error('登录账号不能为空')
        return false
      }
      if(!row.password || row.password.trim().length<1){
        this.$message.error('登录密码不能为空')
        return false
      }
      saveOrUpdateParkingLotGate(row).then(res =>{
        if (res.code === 30 && res.result) {
          this.$message.success('该通道配置成功')
          this.$nextTick(()=>{
            if(!row.id){
              row.id = res.returnObject.id
              row.code = res.returnObject.code
            }
            row.haveChanged = false
            this.itemKey++
          })
        }
      })
    },
    goDelete(item, type){
      if(item.id){
        this.$confirm(`确定要删除${type==0?'入口':'出口'}:${item.name} 吗?`, "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(async () => {
              deleteParkingLotGate({id: item.id}).then(res =>{
                if (res.code === 30 && res.result) {
                  this.$message.success(`成功删除${type==0?'入口':'出口'}:${item.name} `)
                  this.removeItem(item, type)
                }
              })
            })
            .catch(() => {
            });
      }else{
        this.removeItem(item, type)
      }
    },
    removeItem(item, type){
      if(type==0){
        let inletIndex = this.inletList.indexOf(item); // 找到要删除的元素的索引
        if (inletIndex > -1) {
          this.inletList.splice(inletIndex, 1); // 删除该元素
        }
      }else{
        let outletIndex = this.outletList.indexOf(item); // 找到要删除的元素的索引
        if (outletIndex > -1) {
          this.outletList.splice(outletIndex, 1); // 删除该元素
        }
      }
      this.$nextTick(()=>{
        this.itemKey++
      })
    },
  },
  mounted() {
    if (this.parkingId) {
      this.search();
    }
  },
};
</script>

<style lang="less" scoped>

</style>
